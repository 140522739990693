<template>
  <div>
    <apexchart
      height="300"
      type="donut"
      v-if="typeof series === 'object'"
      :options="options"
      :series="series"
    />
  </div>
</template>

<script>
const fontFamily = "'greycliff-cf', sans-serif"

export default {
  name: 'DonutChart',
  computed: {
    options() {
      return {
        colors: ['#EC0E1E', '#FFEA00', '#5DB000', '#B5B5B5'],
        chart: {
          type: 'donut',
          animations: {
            enabled: false
          },
        },
        stroke: {
          colors: ['transparent'],
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              labels: {
                show: true,
                name: {
                  offsetY: 25
                },
                total: {
                  show: true,
                  showAlways: true,
                  fontFamily,
                  label: this.totalLabel,
                  color: '#454444',
                },
                value: {
                  show: true,
                  fontFamily,
                  fontWeight: 900,
                  fontSize: '32px',
                  color: '#454444',
                  offsetY: -15,
                },
              },
              size: '80%'
            }
          }
        },
        labels: this.labels,
        dataLabels: {
          enabled: false,
        },
        legend: {
          position: 'bottom',
          fontFamily,
          fontWeight: 500,
          labels: {
            colors: '#454444',
          },
          onItemClick: {
              toggleDataSeries: false
          },
        },
      }
    }
  },
  props: {
    series: {
      type: Array,
      required: true
    },
    labels: {
      type: Array,
      required: false,
      default: () => []
    },
    totalLabel: {
      type: String,
      required: false,
      default: undefined
    }
  },
  components: {
    apexchart: () => import('vue-apexcharts')
  }
}
</script>